import LocalizedStrings from 'react-localization';
import en from './en.json';
import es from './es.json';
import ja from './ja.json';
import vi from './vi.json';
import id from './id.json';
import ru from './ru.json';
import zh from './zh.json';
import fr from './fr.json';
import mn from './mn.json';
import th from './th.json';

const locals = {
    en,
    es,
    ja,
    vi,
    id,
    ru,
    zh,
    fr,
    mn,
    th,
};

export const langsSelectList = {
    en: 'English',
    ja: '日本語',
    es: 'Español',
    vi: 'Tiếng Việt',
    id: 'Bahasa Indonesia',
    ru: 'Pусский',
    zh: '中文',
    fr: 'Français',
    mn: 'Монгол',
    th: 'ภาษาไทย',
};

const Localization = new LocalizedStrings(locals);
console.log(Localization);

export default Localization;

import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { zIndex, palette, classes, size, breakPoint, CloseButton } from '@mirinae/react-ui';
import { explorerPaths, lessonsPaths, membersPaths, authPaths, navigateExternal } from '@mirinae/defines/paths';
import { os } from '@mirinae/js-utils/utils/userAgent';

import { Button } from './gnbStyles';
import ConfigLayer from './elements/ConfigLayer';
import AuthLayer from './elements/AuthLayer';
import Playlist from './elements/Playlist';
import IconLogo from './assets/IconLogo';
import IconControl from './assets/IconControl';
import IconAnalyze from './assets/IconAnalyze';
import IconAnalyzeActive from './assets/IconAnalyzeActive';
import IconAnalyzeDisable from './assets/IconAnalyzeDisable';
import IconMusicPlay from './assets/IconMusicPlay';
import IconMusicPlayActive from './assets/IconMusicPlayActive';
import IconMusicPlayDisable from './assets/IconMusicPlayDisable';
import IconLessons from './assets/IconLessons';
import IconLessonsActive from './assets/IconLessonsActive';
import IconLessonsDisable from './assets/IconLessonsDisable';
import IconBooks from './assets/IconBooks';
import IconBooksActive from './assets/IconBooksActive';
import IconBooksDisable from './assets/IconBooksDisable';
import IconRocket from './assets/IconRocket';
import IconRocketActive from './assets/IconRocketActive';
import IconRocketDisable from './assets/IconRocketDisable';
import IconUser from './assets/IconUser';
import IconUserActive from './assets/IconUserActive';
import IconNeologisms from './assets/IconNeologisms';
import IconNeologismsActive from './assets/IconNeologismsActive';
import IconNavHistory from './assets/IconNavHistory';
import IconNavHistoryActive from './assets/IconNavHistoryActive';
import IconNavHelp from './assets/IconNavHelp';
import IconNavHelpActive from './assets/IconNavHelpActive';
import IconNavFeedback from './assets/IconNavFeedback';
import IconNavFeedbackActive from './assets/IconNavFeedbackActive';
import IconNeologismsDisable from './assets/IconNeologismsDisable';

// import IconUserSqure from './assets/IconUserSqure';
import IconHistory from './assets/IconHistory';
import IconUserDefault from './assets/IconUserDefault';
import { wikinaePaths } from '../../defines/paths';
import { getTrialStatus } from '../../defines/datas';
import Localization from '../../il8n';
import IconHamburger from '@mirinae/react-gnb/assets/IconHamburger';
import AnalyzerTopFixedBannerContainer from '@explorer/containers/common/AnalyzerTopFixedBannerContainer';
import { useCookies } from 'react-cookie';
import IconChevronRight from '@mirinae/react-gnb/assets/IconChevronRight';

const HeaderFixer = styled.div`
    height: ${size.header};
    #profile-page & {
        @media only screen and (max-width: ${breakPoint.common.lg}) {
            height: 0;
        }
    }
`;
const HeaderWrapper = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: ${zIndex.header};
    min-width: ${size.mobileWidth};
    height: ${size.header};
    background-color: #f6fbfb; /* ${props => (props.mode === 'analyzer' ? '#f6fbfb' : '#fff')}; */
    /* padding-left: 40px; */
  
    #profile-page & {
        @media only screen and (max-width: ${breakPoint.common.lg}) {
            height: 0;
        }
    }

    #plan-page &,
    #exploration.${classes.parseTree} & {
        background-color: ${palette.primary.white};
    }

    @media only screen and (min-width: ${breakPoint.common.lg}) {
        border-bottom: 1px solid ${palette.gray[7]};
    }
    @media only screen and (max-width: ${breakPoint.common.lg}) {
        background-color: #F6FBFB;
        padding: 0 10px;
        #lyric-explorer-app-root & {
            background-color: #FDF8FF;
            padding: 17px 15px 16px;
        }
        #plan-page &,
        #exploration.${classes.parseTree} & {
            background-color: ${palette.primary.white};
        }
        &.has-border {
            border-bottom: 1px solid ${palette.gray[7]};
        }
        #kpop & {
            background-color: #F6F2FF;
        }
    }
    .${classes.mobile}#history-page &,
    .${classes.mobile}#reference-page &,
    .${classes.mobile}#glossary-page & {
        border-bottom: 0 !important;
    //     position: fixed;
    //     left: 0;
    //     right: 0;
    }
`;
const HeaderInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: ${size.wrap};
    height: 100%;
    margin: auto;
    background-color: ${props => (props.mode === 'lessons_landing' ? '#f6fbfb' : props.mode === 'lyric-explorer' ? '#FDF8FF' : '#f6fbfb')};
    
    .${classes.parseTree} {
        background-color: #fff !important;
    }

    @media only screen and (max-width: ${breakPoint.common.lg}) {
        justify-content: flex-start;
    }
`;
export const Logo = styled.div`
    // position: absolute;
    //left: 20px;
    height: 35px;
    a {
        display: block;
        height: 100%;
    }
    #profile-page & {
        @media only screen and (max-width: ${breakPoint.common.lg}) {
            display: none;
        }
    }
    
    #plan-page & {
        padding: 10px;
    }
    
`;

const HamburgerMenu = styled.div`
    margin-bottom: 5px;
    cursor: pointer;
`;

const SideNav = styled.nav`
    display: none;

    @media only screen and (min-width: ${breakPoint.common.lg}) {
        display: flex;
        flex-direction: column;
        position: fixed;
        gap: 30px;
        top: 0px;
        left: 0px;
        align-items: ${props => (props.wide ? 'start' : 'center')};
        justify-content: start;
        width: ${props => (props.wide ? 220 : 80)}px;
        height: 100vh;
        background: #FFF;
        box-shadow: -4px -2px 16px 0px #9FBCBA, -4px -2px 16px 0px #FFF;
        padding-top: 24px;
        padding-left: ${props => (props.wide ? 20 : 0)}px;
      
        transition: width ease-in-out 100ms;
        z-index: ${zIndex.header + 10};
      
        a {
            display: flex;
            flex-direction: ${props => (props.wide ? 'row' : 'column')};
            align-items: center;
            justify-content: start;
            gap: 5px;
            width: ${props => (props.wide ? '180px' : '100%')};
            color: ${palette.gray[5]};
            font-size: ${props => (props.wide ? 14 : 10)}px;
            font-weight: 600;
            text-transform: capitalize;
            text-align: ${props => (props.wide ? 'left' : 'center')};
        }
        a:hover,
        a[data-active='true'] {
            color: ${palette.main.darken[1]};
        }
        a[data-disabled='true'] {
            cursor: default;
            color: ${palette.gray[5]};
            pointer-events: none;
        }

        a.explorer[data-active='true'], a.explorer:hover {
            color: #8868BD !important;
        }
        
        a.gnb-profile {
            display: none;
        }
        a + a {
            // margin-left: 50px;
        }
        svg {
            // margin-right: 5px;
        }
    }
`;

const BottomNav = styled.nav`
    display: none;
  
    @media only screen and (max-width: ${breakPoint.common.lg}) {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: ${zIndex.header};
        height: ${size.bottom};
        padding: 0 10px;
        background-color: ${palette.primary.white};
        border-top: 1px solid ${palette.gray[7]};
      
        transition: width ease-in-out 100ms;
        z-index: ${zIndex.header + 10};
      
        a {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 25%;
            padding: 16px 2px;
            text-align: center;
            word-break: break-word;
            color: ${palette.gray[5]};
            font-size: 10px;
            font-weight: 500;
            text-transform: capitalize;
        }
        a:hover,
        a[data-active='true'] {
            color: ${palette.main.darken[1]};
            font-weight: 700;
        }
        a[data-disabled='true'] {
            cursor: default;
            color: ${palette.gray[5]};
            pointer-events: none;
        }
      
        a.explorer[data-active='true'], a.explorer:hover {
            color: #8868BD !important;
        }
        
        a.gnb-pro {
            display: none;
        }
        svg {
            margin-bottom: 5px;
        }
        [lang=vi] & a {
            font-size: 12px;
        }
    
        @media only screen and (max-width: ${breakPoint.common.sm}) {
            padding: 0 20px;
        }
        @media only screen and (max-width: 281px) {
            padding: 0 10px;
        }
    }
`;

const HeaderConfig = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    height: 100%;

    @media only screen and (max-width: ${breakPoint.common.lg}) {
        right: 0px;
    }
`;
const Buttons = styled.div`
    display: flex;
    align-items: center;
    & > * + * {
        margin-left: 15px;
    }

    @media only screen and (min-width: ${breakPoint.common.lg}) {
        margin-right: 10px;
        padding-right: 10px;
        :after {
            content: '';
            display: block;
            width: 1.5px;
            height: 14px;
            margin-left: 20px;
            background-color: ${palette.gray[6]};
        }
    }
`;

const TryProFree = styled(Link)`
    display: flex;
    padding: 0 10px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #EFF2FF;
    & span {
        text-edge: cap;
        font-size: 13px;
        font-weight: 700;
        background: var(--pro-gradient-01, linear-gradient(90deg, #3499FF -70.26%, #3A3985 121.84%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    &:hover {
        background: #E0E6FF;
    }
`;

// It'll be added at the next time, not Mirinae 1.0
// const AlertButton = styled(Button)`
//     &:hover:not(:disabled) svg {
//         path {
//             fill: ${palette.main.darken[1]};
//         }
//     }
//     &:disabled svg {
//         path {
//             fill: ${palette.gray[5]};
//         }
//     }
// `;

const HistoryButton = styled(Button)`
    &:hover:not(:disabled) svg {
        path {
            fill: ${palette.main.darken[1]};
        }
    }
    &:disabled svg {
        path {
            fill: ${palette.gray[5]};
        }
    }
`;

const AuthArea = styled.div`
    display: flex;
    align-items: center;
    @media only screen and (max-width: ${breakPoint.common.lg}) {
        display: none;
    }
`;
export const LoginButton = styled(Button)`
    line-height: 22px;
    color: ${palette.gray[1]};
    font-weight: 600;
    text-transform: capitalize;
    white-space: nowrap;
    :hover {
        color: ${palette.main.darken[1]};
    }
`;
export const SignupButton = styled(Button)`
    height: 38px;
    margin-left: 10px;
    padding: 0 20px;
    border-radius: 30px;  
    background-color: ${palette.main.main};
    color: ${palette.primary.white};
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    white-space: nowrap;
    :hover {
        background-color: ${palette.main.darken[1]};
    }
`;
const UserButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    overflow: hidden;
    background-color: #EDF5F5;
    border-radius: 11px;
    color: #525252;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    :hover {
        background-color: #E0FBFA;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const MenuLink = styled(Link)`
    position: relative;
    color: ${palette.gray[5]}; // ${props => (props.menu.msg && props.wide ? '#3C3C3B !important' : palette.gray[5])};
    padding: 0 5px;
    min-width: 75px;
    &:hover,
    &[data-active='true'] {
        color: ${palette.main.darken[1]} !important;
    }
    &.mobileOnly {
        @media only screen and (min-width: ${breakPoint.common.lg}) {
            display: none;
        }
    }
    &.desktopOnly {
        @media only screen and (max-width: ${breakPoint.common.lg}) {
            display: none;
        }
    }
`;

const Separator = styled.div`
    display: none;
    @media only screen and (min-width: ${breakPoint.common.lg}) {    
        width: ${props => (props.wide ? 180 : 30)}px;
        height: 1px;
        border-radius: 1px;
        background: #DFDFDF;
      
        &:last-child {
            display: none;
        }
    }
`;

const MenuMsg = styled.div`
    color: ${palette.gray[5]};
    font-size: 11.5px;
    font-weight: 600;
    text-align: center;
`;

const MenuItem = ({ user, menu, wide }) => {
    const location = useLocation();
    const [hover, setHover] = useState(false);

    const getIcons = (name) => {
        switch (name) {
        default:
        case 'analyze': return {
            default: <IconAnalyze />,
            active: <IconAnalyzeActive />,
            desable: <IconAnalyzeDisable />,
        };
        case 'explorer': return {
            default: <IconMusicPlay />,
            active: <IconMusicPlayActive />,
            desable: <IconMusicPlayDisable />,
        };
        case 'lessons': return {
            default: <IconLessons />,
            active: <IconLessonsActive />,
            desable: <IconLessonsDisable />,
        };
        case 'library': return {
            default: <IconBooks />,
            active: <IconBooksActive />,
            desable: <IconBooksDisable />,
        };
        case 'neoDictionary': return {
            default: <IconNeologisms />,
            active: <IconNeologismsActive />,
            desable: <IconNeologismsDisable />,
        };
        case 'pro': return {
            default: <IconRocket />,
            active: <IconRocketActive />,
            desable: <IconRocketDisable />,
        };
        case 'profile': return {
            default: <IconUser />,
            active: <IconUserActive />,
            desable: <IconUser />,
        };
        case 'history': return {
            default: <IconNavHistory />,
            active: <IconNavHistoryActive />,
        };
        case 'helpcenter': return {
            default: <IconNavHelp />,
            active: <IconNavHelpActive />,
        };
        case 'feedback': return {
            default: <IconNavFeedback />,
            active: <IconNavFeedbackActive />,
        };
        }
    };
    const icons = getIcons(menu.id);
    const active = (location.pathname === '/explorer' && menu.link === '/') ||
        menu.active ||
        (menu.link !== '/' && location.pathname.startsWith(menu.link)) ||
        (menu.link === '/' && location.pathname === menu.link);

    return menu.link.match(/http/) ? (
        <a
            href={menu.link} className={`gnb-${menu.id}`}
            data-active={active} data-disabled={menu.disabled}
            onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}
        >
            {menu.disabled ? icons.disable : active ? icons.active : hover ? icons.active : icons.default}
            {menu.name}
        </a>
    ) : (
        <>
            { menu.separator && <Separator wide={wide} /> }
            { wide && menu.msg && <MenuMsg>{menu.msg}</MenuMsg> }
            <MenuLink
                reloadDocument
                to={menu.link}
                data-active={active} data-disabled={menu.disabled}
                onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}
                menu={menu} wide={Boolean(wide).toString() || 'false'}
                className={`gnb-${menu.id} ${menu.className || ''}`}
                onClick={e => menu.onClickLink && menu.onClickLink(e)}
            >
                { menu.disabled ? icons.disable : active ? icons.active : hover ? icons.active : icons.default}
                {menu.name}
                { wide && menu.chevronRight && <IconChevronRight /> }
            </MenuLink>
            { menu.separator && <Separator wide={wide} /> }
        </>
    );
};

const defaultLinks = {
    home: '/',
    explorer: explorerPaths.home,
    lyricExplorer: '/lyric-explorer',
    lessons: lessonsPaths.home,
    library: explorerPaths.library,
    wikinae: wikinaePaths.home,
    neoDictionary: wikinaePaths.home,
    pro: membersPaths.plan,
    profile: membersPaths.profile,
    login: `${authPaths.login}?redirect=${explorerPaths.home}`,
    signup: `${authPaths.signup}?redirect=${explorerPaths.home}`,
    helpcenter: explorerPaths.help,
    history: explorerPaths.history,
    feedback: explorerPaths.userfeedback,
};

const HeaderComponent = ({
    isApp = false,
    mode = 'analyze', // 'analyze || lessons || members'
    localization = {
        analyzer: 'analyzer', explorer: 'explorer', lessons: 'lessons', library: 'library', neoDictionary: 'Neologism Dictionary', neologisms: 'Neologisms', profile: 'profile',
        history: 'history', helpcenter: 'help center', feedback: 'send feedback', wikiMsg: 'Wanna learn some Korean slang?',
        'rp-login': 'Log In', 'auth-signup': 'Sign Up',
        'learning-mode': 'Learning mode', easy: 'Easy',
        'font-size': 'Scale', small: 'Small', normal: 'Normal', large: 'Large',
        'phonetic-speed': 'Pronunciation speed', 'speed': 'Speed', slow: 'Slow', fast: 'Fast',
        'phonetic-gender': 'Pronunciation gender','gender': 'Gender', male: 'Male', female: 'Female',
        'analyze-setting': 'Analyzer Setting', display: 'Display', language: 'Laguage',
        phonetic: 'Pronunciation audio', romanization: 'Romanization', 'romanization-setting': 'Romanization setting',
        'config-romanization-text': 'Shows the Korean Pronunciation in the Roman alphabet.',
        'config-language-text': 'Language selection is not supported for Lessons.',
        account: 'Account', help: 'Help', 'sign-out': 'Sign Out',
    },
    user = { name: '', email: '' },
    links = {},
    onClickLogo = () => {},
    onLogout = () => {},
    prefs,
    onClickPrefs = () => {},
    langsList, onSelectLang = () => {},
    gnbMenus,
    userType = 'free',
    hasNav = true,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [cookie] = useCookies(['searched-once', 'closed-top-banner']);

    links = Object.assign(defaultLinks, links);

    // if the profile link is clicked and we are logged out, redirect to app or web login; need to be logged-in to see the account profile page
    const onClickProfileLink = (e) => {
        const guestMode = user && user.role === 'guest' || user.name === '';
        if (guestMode) {
            e.preventDefault();
            if (window.PaymentChannel) {
                window.ProfileChannel.postMessage(JSON.stringify({ command: 'signOut', value: true })); // webview
            } else if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler('ProfileChannel', { command: 'signOut', value: true });
            } else {
                navigateExternal(`${authPaths.login}?redirect=${membersPaths.profile}`, { replace: true });
            }
        }
    };

    const gnbDefaultMenu = [
        { id: 'analyze', name: localization.analyzer, link: links.home, disabled: false },
        // { id: 'lyricExplorer', name: localization.explorer, link: links.explorer, disabled: false, className: 'explorer' },
        { id: 'lessons', name: localization.lessons, link: links.lessons, disabled: false },
        { id: 'library', name: localization.library, link: links.library, disabled: false },
        { id: 'neoDictionary', name: localization.neoDictionary, mobileName: localization.neologisms, link: links.wikinae, className: 'desktopOnly', separator: true, msg: localization.wikiMsg, chevronRight: true, disabled: false },
        { id: 'neoDictionary', name: localization.neologisms, mobileName: localization.neologisms, link: links.wikinae, className: 'mobileOnly', disabled: false },
        // { id: 'pro', name: 'pro', link: links.pro, disabled: false },
        { id: 'profile', name: localization.profile, link: links.profile, onClickLink: onClickProfileLink, className: 'mobileOnly', disabled: false },
        // following for the wide-mode side GNB only
        { id: 'history', name: localization.history, link: links.history, disabled: false, wideOnly: true },
        { id: 'helpcenter', name: localization.helpcenter, link: links.helpcenter, disabled: false, wideOnly: true },
        { id: 'feedback', name: localization.feedback, link: links.feedback, disabled: false, wideOnly: true },
    ];

    const [wideMenu, setWideMenu] = useState(false);
    const [dipslayAuthLayer, setDisplayAuthLayer] = useState(false);
    const [displayConfigLayer, setDisplayConfigLayer] = useState(false);
    const [menus, setMenus] = useState(gnbMenus || gnbDefaultMenu);
    const [profileImg, setProfileImg] = useState(user.profile?.image);
    const [hasBorder, setHasBorder] = useState(false);
    const hoverConfigLayer = useRef(false);
    const hoverAuthLayer = useRef(false);
    const trialStatus = getTrialStatus(user);
    const guestMode = user?.role === 'guest';

    const handleLogout = () => {
        onLogout();
    };

    const handleCloseConfigLayer = (e) => {
        if (!os.isOthers || hoverConfigLayer.current) return;
        setDisplayConfigLayer(false);
        e.stopPropagation();
    };

    const handleCloseAuthLayer = (e) => {
        if (!os.isOthers || hoverAuthLayer.current) return;
        setDisplayAuthLayer(false);
        e.stopPropagation();
    };

    const handleScroll = () => {
        setHasBorder(window.scrollY > 0);
    };
    useEffect(() => {
        // setDisplayAuthLayer(false);
        // setDisplayConfigLayer(false);

        document.body.addEventListener('click', handleCloseConfigLayer, false);
        document.body.addEventListener('click', handleCloseAuthLayer, false);
        window.addEventListener('scroll', handleScroll);
        return () => {
            document.body.removeEventListener('click', handleCloseConfigLayer);
            document.body.removeEventListener('click', handleCloseAuthLayer);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setMenus((gnbMenus || gnbDefaultMenu).filter(n => (userType !== n.id)));
    }, [userType, localization.analyze])

    useEffect(() => {
        setDisplayAuthLayer(false);
        setDisplayConfigLayer(false);
    }, [location.pathname, window.location.host]);

    useEffect(() => {
        if (!os.isOthers) {
            if (displayConfigLayer) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        }
    }, [displayConfigLayer]);

    const isIgnoreOneLesson = () => location?.pathname?.match(/onelesson/i)?.length > 0;

    const userImgError = () => setProfileImg(null);

    return (
        <HeaderFixer>
            <HeaderWrapper className={hasBorder ? 'has-border' : null} mode={mode}>
                <HeaderInner id="header" mode={mode}>
                    <Logo>
                        {links.home.match(/http/) ? (
                            <a data-t="gnb-header-home-link" href={links.home} onClick={e => onClickLogo && onClickLogo(e)}><IconLogo /></a>
                        ) : (
                            <Link reloadDocument data-t="gnb-header-home-link" to={links.home} onClick={e => onClickLogo && onClickLogo(e)}><IconLogo /></Link>
                        )}
                    </Logo>
                    { mode === 'analyze' && !guestMode && trialStatus === 'no-trial' && cookie['searched-once'] && !cookie['closed-top-banner'] && <AnalyzerTopFixedBannerContainer /> }
                    { hasNav
                        && (
                            <>
                                <SideNav wide={wideMenu}>
                                    { wideMenu ? (
                                        <CloseButton theme="large" onClick={() => setWideMenu(false)} />
                                    ) : (
                                        <HamburgerMenu onClick={() => setWideMenu(true)}><IconHamburger /></HamburgerMenu>
                                    )}
                                    { menus.map((m, i) => (!m.wideOnly || wideMenu) && (
                                        <MenuItem menu={m} key={i} wide={wideMenu} user={user} />
                                    ))}
                                </SideNav>
                                <BottomNav>
                                    { menus.map((m, i) => (!m.wideOnly || wideMenu) && (
                                        <MenuItem menu={m} key={i} user={user} />
                                    ))}
                                </BottomNav>
                            </>
                        )}
                    <HeaderConfig>
                        <Buttons>
                            { trialStatus && (userType === 'free' && userType !== 'guest') && (
                                <TryProFree reloadDocument to={membersPaths.plan}><span>{Localization[trialStatus === 'no-trial' ? 'try-pro-free' : 'propopup-title']}</span></TryProFree> )}
                            { (mode === 'analyze' || mode === 'lessons' /* not yet, once everything is localized */) && (
                                <>
                                    {mode !== 'lessons' && (
                                    <HistoryButton
                                        type="button"
                                        onClick={() => navigate(links.history)}
                                    >
                                        <IconHistory />
                                    </HistoryButton>)}
                                    {/* Low priority */}{/* <AlertButton type="button"><IconAlert /></AlertButton> */}
                                    { /* (user.loginType && user.loginType !== 'guest') &&  => always show settings options now */
                                        !isIgnoreOneLesson() && (
                                        <Button
                                            type="button"
                                            active={displayConfigLayer}
                                            onClick={() => setDisplayConfigLayer(prev => !prev)}
                                            onMouseOver={() => hoverConfigLayer.current = true}
                                            onMouseOut={() => hoverConfigLayer.current = false}
                                        >
                                            <IconControl active={displayConfigLayer || hoverConfigLayer.current}/>
                                        </Button>
                                    )}
                                </>
                            )}
                        </Buttons>
                        <AuthArea>
                            {(!user.loginType || user.loginType === 'guest') ? (
                                <>
                                    <LoginButton type="button" onClick={() => { window.location.href = links.login }}>{localization['rp-login']}</LoginButton>
                                    <SignupButton type="button" onClick={() => { window.location.href = links.signup }}>{localization['auth-signup']}</SignupButton>
                                </>
                            ) : (
                                <UserButton
                                    type="button"
                                    onClick={() => setDisplayAuthLayer(prev => !prev)}
                                    onMouseOver={() => hoverAuthLayer.current = true}
                                    onMouseOut={() => hoverAuthLayer.current = false}
                                >
                                    {profileImg ? <img src={profileImg} onError={userImgError} alt="" /> : <IconUserDefault />}
                                </UserButton>
                            )}
                        </AuthArea>
                        {(mode === 'analyze' || mode === 'lessons') && (
                            <ConfigLayer
                                theme={displayConfigLayer ? 'show' : 'hide'}
                                langsList={langsList} onSelectLang={onSelectLang}
                                prefs={prefs} localization={localization}
                                onClickPrefs={onClickPrefs}
                                onClose={() => setDisplayConfigLayer(false)}
                                onMouseOver={() => hoverConfigLayer.current = true}
                                onMouseOut={() => hoverConfigLayer.current = false}
                            />
                        )}
                        {dipslayAuthLayer && (
                            <AuthLayer
                                name={user.name} email={user.email}
                                links={links} onLogout={handleLogout}
                                localization={localization}
                                onMouseOver={() => hoverAuthLayer.current = true}
                                onMouseOut={() => hoverAuthLayer.current = false}
                            />
                        )}
                    </HeaderConfig>
                </HeaderInner>
            </HeaderWrapper>
            { mode === 'lyric-explorer' && <Playlist /> }
        </HeaderFixer>
    );
};

export default HeaderComponent;
import React from 'react';

import Level0 from '../icons/Level0';
import Level1 from '../icons/Level1';
import Level2 from '../icons/Level2';
import Level3 from '../icons/Level3';
import Level4 from '../icons/Level4';
import Level5 from '../icons/Level5';
import Level6 from '../icons/Level6';
import LevelNo from '../icons/LevelNo';
import LevelAll from '../icons/LevelAll';
import LevelIdiom from '../icons/LevelIdiom';
import LevelNeo from '../icons/LevelNeo';
import LevelProv from '../icons/LevelProv';
import LevelPhrase from '../icons/LevelPhrase';

const LevelButtons = props => {
    switch (props.level.toLowerCase()) {
    case '0':
        return <Level0 width={props.width} height={props.height} disabled={!props.state} />;
    case '1':
        return <Level1 width={props.width} height={props.height} disabled={!props.state} />;
    case '2':
        return <Level2 width={props.width} height={props.height} disabled={!props.state} />;
    case '3':
        return <Level3 width={props.width} height={props.height} disabled={!props.state} />;
    case '4':
        return <Level4 width={props.width} height={props.height} disabled={!props.state} />;
    case '5':
        return <Level5 width={props.width} height={props.height} disabled={!props.state} />;
    case '6':
        return <Level6 width={props.width} height={props.height} disabled={!props.state} />;
    case 'no':
        return <LevelNo width={props.width} height={props.height} disabled={!props.state} />;
    case 'idiom':
        return <LevelIdiom width={props.width} height={props.height} disabled={!props.state} />;
    case 'neologism':
    case 'neo':
        return <LevelNeo width={props.width} height={props.height} disabled={!props.state} />;
    case 'proverb':
    case 'prov':
        return <LevelProv width={props.width} height={props.height} disabled={!props.state} />;
    case 'phrase':
        return <LevelPhrase />;
    case 'all':
        return <LevelAll width={props.width} height={props.height} disabled={!props.state} />;
    default:
        return null;
    }
};

LevelButtons.defaultProps = {
    state: true,
};

export default LevelButtons;
import React from 'react';
import styled from 'styled-components/macro';

const SVG = styled.svg`
    & path {
        fill: ${props => props.color || '#3C3C3B'};
    }
    &:hover {
        & path {
            fill: ${props => props.hoverColor || '#3C3C3B'};
        }
    }
`;

const VolumeOn = props => (
    <SVG style={{ pointerEvents: props.pointerEvents || 'none' }} width={props.width || '18'} height={props.height || '12'} color={props.color} hoverColor={props.hoverColor} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 6.6V5.4C0 4.40589 0.805887 3.6 1.8 3.6H1.956C2.52477 3.6006 3.08937 3.50287 3.62486 3.31114L6.98229 0.433714C7.30855 0.153881 7.72417 3.55602e-05 8.154 0C8.78029 0 9.288 0.507709 9.288 1.134V10.866C9.288 11.4923 8.78029 12 8.154 12C7.72417 12 7.30855 11.8461 6.98229 11.5663L3.62486 8.68886C3.08937 8.49713 2.52477 8.3994 1.956 8.4H1.8C0.805887 8.4 0 7.59411 0 6.6Z" />
        <path d="M13.7477 1.53411C15.8425 4.14277 15.8425 7.85689 13.7477 10.4655C13.4218 10.8724 13.4874 11.4665 13.8943 11.7924C14.3011 12.1183 14.8952 12.0527 15.2211 11.6458C17.868 8.34716 17.868 3.65249 15.2211 0.353828C14.8952 -0.0530479 14.3011 -0.11867 13.8943 0.207257C13.4874 0.533184 13.4218 1.12724 13.7477 1.53411ZM11.0717 9.53469C11.4781 9.86007 12.0713 9.79445 12.3968 9.38811C14.007 7.4162 14.007 4.58346 12.3968 2.61154C12.186 2.34865 11.8509 2.2182 11.5178 2.26934C11.1847 2.32049 10.9042 2.54545 10.782 2.85949C10.6597 3.17352 10.7143 3.52893 10.9251 3.79183C11.9655 5.08001 11.9655 6.91964 10.9251 8.20783C10.7683 8.40311 10.6957 8.65274 10.7232 8.90166C10.7507 9.15058 10.8761 9.37834 11.0717 9.53469Z" />
    </SVG>
);

export default VolumeOn;
import { explorerPaths } from '@mirinae/defines/paths';

let _rootDomain;
let _cookieDomain;
// if (!process.env.REACT_APP_IS_ALPHA && (process.env.REACT_APP_MODE === 'LOCAL' || process.env.REACT_APP_LOCAL)) { //  HEY, need to be able to recognize app source for  localhost testing!!  only way now is https://[app.]local.mirinae.io with local Nginx SSL terminator

if (window.location.hostname.match(/local/)) {
	_rootDomain = 'localhost';
    _cookieDomain = 'localhost';
} else {
    _rootDomain = window.location.host.match(/((.+\.)|(^))([a-z\-]+\.[a-z][a-z][a-z]*)$/)[4];
    _cookieDomain = `.${_rootDomain}`;
}

export const rootDomain = _rootDomain;
export const cookieDomain = _cookieDomain;

console.log('rootDomain', rootDomain, 'cookieDomain', cookieDomain, 'href', window.location.href);

// build external URL, including any flutter app version parameter, so other services know app version
export const buildExternalURL = (path, versionParam) => {
    const vp = versionParam || window.versionParam || window.appVersion;
    const qpSep = path.indexOf('?') > 0 ? '&' : path.endsWith('/') ? '?' : '/?';
    return vp ? `${path}${qpSep}v=${vp}` : path;
}

export const ExternalLinks = {
    mirinaeCompany: 'https://company.mirinae.io',
    TermsAndConditions: 'https://company.mirinae.io/terms-and-conditions/',
    PrivacyPolicy: 'https://company.mirinae.io/privacy-policy/',
    EULA: 'https://company.mirinae.io/eula/',

    TTMIK: 'https://talktomeinkorean.com/',
    howToStudyKorean: 'https://www.howtostudykorean.com/',
    krdictCopyright: 'https://krdict.korean.go.kr/eng/kboardPolicy/copyRightTermsInfo?nation=eng',
    naverCorp: 'https://developers.naver.com/products/terms/',
    khaiii: 'https://github.com/kakao/khaiii',
    apache2: 'https://www.apache.org/licenses/LICENSE-2.0',

    chromeExtensionMarket: 'https://chrome.google.com/webstore/detail/mirinae-for-chrome/nagkhplkbmnpanjfjpoepjmikmegcihp',
    chromeExtensionManifest: 'chrome-extension://nagkhplkbmnpanjfjpoepjmikmegcihp/manifest.json',
    tutorialVideo: 'https://www.youtube.com/watch?v=df7YD6EuZHw',
    survey: 'https://forms.gle/gyN2ux9Kp9cVUcwQA',

    sns: {
        twitter: 'https://twitter.com/mirinae_io',
        facebook: 'https://www.facebook.com/mirinae.io',
        youtube: 'https://www.youtube.com/c/mirinae_io',
        instagram: 'https://www.instagram.com/mirinae.io/',
        linkedIn: 'https://www.linkedin.com/company/mirinae',
        pinterest: 'https://www.pinterest.co.kr/mirinaean',
    },
    email: 'support@mirinae.io',
    app: {
        apple: 'https://apps.apple.com/kr/app/mirinae-explorer/id1564646065',
        android: `intent://${window.location.href.replace(window.location.protocol, '').replace('//', '')}#Intent;scheme=https;package=com.mirinae.mirinae;end`,
        playStore: 'https://play.google.com/store/apps/details?id=com.mirinae.mirinae',
    },

    communityGuide: 'https://company.mirinae.io/guidelines/',
    support: 'https://support.mirinae.io/',
    blog: 'https://mirinae.io/blogs/',
    userFeedback: explorerPaths.feedback,
    help: explorerPaths.help,
    glossary: explorerPaths.glossary,
};
